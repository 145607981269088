import React from 'react'
const AltSiteName = " - Balgores"
const NewsBaseURL = '/about/latest-property-news'
const TeamBaseURL = '/about/meet-the-team'
const CarerrBaseURL = '/about/careers'
const AreaguideBaseURL = '/branches'
const OfficeBaseURL = '/branches/estate-agents-in-'
const ContactFormURL = '/contact'
const SaleURL = '/properties-for-sale'
const LetURL = '/properties-to-rent'
const NewHomeSaleURL = '/new-homes-for-sale'
const NewHomesLetURL = '/new-homes-to-rent'
const BranchURL = '/branches'
export {
    AltSiteName,
    NewsBaseURL,
    TeamBaseURL,
    CarerrBaseURL,
    AreaguideBaseURL,
    OfficeBaseURL,
    ContactFormURL,
    SaleURL,
    LetURL,
    BranchURL,
    NewHomeSaleURL,
    NewHomesLetURL
}
